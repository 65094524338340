<template>
  <v-app id="inspire">
    <v-layout row justify-center>
      <div class="animation site-navbar-small dashboard" id="default-page">
        <nav-bar></nav-bar>
        <div class="content">
          <slot />
        </div>
        <br /><br /><br /><br /><br />
         <!-- Footer -->
          <footer-content />
      <!-- End of Footer -->
        <!-- <div class="page"> -->
        <!-- <div class="page-main"> -->
        <!-- <app-navigation></app-navigation> -->
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </v-layout>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterContent from "@/components/FooterContent.vue";

export default {
  components: {
    NavBar,
    FooterContent,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#inspire {
  .v-application {
    background-color: #ffffe0;
  }
  #app {
    text-align: start;
  }
  .content {
    padding-top: 116.018px;
  }
  @media (max-width: 768px) {
    .content {
      padding-top: 35px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  @media (min-width: 1000px) {
    .container--fluid {
      width: 980px;
    }
  }
}
</style>
